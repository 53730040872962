<!--
 * @FileDescription: 自定义列表切换组件
 * @Author: 朱建波
 * @Date: 2023-12-08
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZTabs
 *
 * @Props
 * @property {Array} list                  显示tabs数据
 * @property {String} keyName              list数据为对象时，key值
 * @property {Number} index                选中的index 更新index :index.sync
 * @property {Array} searchConfig          搜索的配置
 * @property {Array} tableConfig           显示列表的配置
 * @property {String} api                  数据请求的api
 * @property {String} hasAdd               是否显示新增按钮
 * @property {String} isDownload           是否显示下载按钮
 * @property {String} onBeforeHttp         请求浅触发事件
 * @property {String} onBeforeSearch       搜索前触发事件
 *
 * @Slots
 *
 * @Methods
 * change(index)                            改变选中index
 * onHandle                                 操作点击事件
 * onExportData                               下载点击事件
 * onAdd                                    新增点击事件
-->
<template>
  <el-tabs v-model="activeName" @tab-click="handleTypeClick">
    <el-tab-pane :label="item.label" :disabled="item.disabled" :name="'s_' + index" v-for="(item, index) in showList" :key="index">
      <list-template
        :hasAdd="hasAdd"
        :isDownload="isDownload"
        :search-config="searchConfig[index]"
        :table-config="tableConfig[index]"
        @onSearch="handleSearch"
        @onReset="handleReSearch"
        :table-data="tableData"
        :total="total"
        :loading="loading"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleClick"
        @onExportData="handDownload"
        @onAdd="handleAdd"
        @onChange="onChange"
      >
      </list-template>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { tableList } from "@/api";
import { mapState } from 'vuex'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    keyName: {
      type: String,
      default: 'label'
    },
    index: {
      type: Number,
      default: 0
    },
    searchConfig: {
      type: Array,
      default: () => []
    },
    tableConfig: {
      type: Array,
      default: () => []
    },
    api: {
      type: [String, Array],
      default: ''
    },
    hasAdd: {
      type: Boolean,
      default: false
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    onBeforeHttp: Function,
    onBeforeSearch: Function
  },
  data() {
    return {
      activeName: '',
      current: 0,
      search: [],
      tableData: [],
      loading: false,
      total: 0,
      pageCount: 0,
      pageSize: 20
    }
  },
  watch: {
    list: {
      handler(val) {
        this.showList = this.TransformData()
        this.search = val.map(() => ({}))
      },
      immediate: true
    },
    index: {
      handler(val) {
        this.current = val
        this.activeName =  's_' + val
      },
      immediate: true
    },
    current(val) {
      this.$emit('change', val, this.list[val])
      this.$emit('update:index', val)
    }
  },
  computed: {
    ...mapState(['page'])
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData && this.getData()
  },
  activated() {
    this.getData && this.getData()
  },
  methods: {
    TransformData() {
      return this.list.map(item => {
        const type = typeof item
        if(type === 'object') {
          return {
            ...item,
            label: item[this.keyName]
          }
        }
        return { label: item }
      })
    },
    handleTypeClick(e) {
      const idx = Number(e.index)
      this.current = idx
      this.$store.commit('setPage', 1);
      this.tableData = []
      this.getData()
    },

    getData() {
      let api = typeof this.api === 'string' ? this.api : this.api[this.current]
      const data = this.onBeforeHttp ?( this.onBeforeHttp(this.search[this.current], this.current) || {}) : {}
      this.getDataApi(api, data)
    },
    getDataApi(api, paramsConfig = {}) {
      if(!api) return this.$message.warning('请传入请求接口!')
      let params = { page: this.page, ...this.search[this.current], ...paramsConfig }
      this.loading = true
      tableList(api, params).then(res => {
        const { list, pageCount, total } = res
        this.total = total
        this.pageCount = pageCount
        this.tableData = list
      }).finally(() => {
        this.loading = false
      })
    },
    handleSearch(val) {
      this.$store.commit('setPage', 1)
      if (this.onBeforeSearch) {
        val = this.onBeforeSearch(val ,this.current) || val
      }
      this.onChange(val)
      this.getData && this.getData()
    },
    handleReSearch() {
      this.$store.commit('setPage', 1);
      // this.search[this.current] = {}
      this.onChange({})
    },
    onChange(val) {
      // this.search[this.current] = val
      this.search.splice(this.current, 1, val)
    },
    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.getData && this.getData()
    },
    handleClick(row, handle) {
      this.$emit('onHandle', row , handle)
    },
    handDownload() {
      this.$emit('onExportData', this.search[this.current], this.current)
    },
    handleAdd() {
      this.$emit('onAdd', this.current)
    }
  }
}
</script>

<style lang="scss" scoped></style>
