<template>
	<div>
		<div style="padding: 24rem 0 0 24rem;">
			<el-alert :closable="false" show-icon title="学生缴费数据仅用于教学管理部识别欠缴费学生并推进催缴工作，不用于财务账目核对。" type="info"></el-alert>
			<TabsTable ref="tabsDom" :api="api" :http="http" :index.sync="index" :list="list" :onBeforeHttp="onBeforeHttp" :searchConfig="searchConfig" :tableConfig="tableConfig" isDownload @onExportData="handDownload" @onHandle="handleClick"></TabsTable>
		</div>
	</div>
</template>

<script>
import TabsTable from "@/components/common/TabsTable";
import {as2_search_class, fileDownload, get_grade, get_school2} from "@/api";
import {tableListMixin} from '@/utils/mixins'

export default {
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	components: {TabsTable},
	mixins: [tableListMixin],
	created() {
		this.getSearchConfig()
		this.initData()
	},
	methods: {
		onBeforeHttp(search, idx) {
			const {status} = this.list[idx].data
			const {showStatus, ...others} = search
			return {status: showStatus ?? status, ...others}
		},
		http() {
			this.getDataApi('/api2/api/educational-routine/art/pay/get-list', {status: this.index})
		},
		handDownload(search, idx) {
			const data = this.onBeforeHttp(search, idx)
			fileDownload('/api2/api/educational-routine/art/pay/download', data, {name: '艺体生转入缴费记录', show: true})
		},
		handleClick(row, handle) {
			const {type} = handle
			let path = ''
			if (type === 'edit') {
				path = 'edit'
			} else if (type === 'view') {
				path = 'detail'
			}
			path && this.$router.push(`./${path}?id=${row.id}`)
		},
		initData() {
			const list2 = [
				{prop: 'student_name', label: '学生姓名'},
				{prop: 'student_no', label: '学号'},
				{prop: 'school', label: '校区'},
				{prop: 'grade', label: '年级'},
				{prop: 'class', label: '转入行政班',},
				{prop: 'major', label: '转入专业方向',},
				{prop: 'payable_amount', label: '应缴总金额'},
				{prop: 'paidin_amount', label: '实缴总金额'},
				{prop: 'outstanding_amount', label: '欠缴总金额'},
				{prop: 'art_at', label: '转入艺体时间'},
				{label: '操作', width: '100rem', handle: true, fixed: 'right', buttons: (row) => row.status === 1 ? [{type: "edit", text: "编辑"}] : [{type: "view", text: "查看"}]}
			]

			const len = this.list.length
			const list3 = [], list4 = []
			for (let i = 0; i < len; i++) {
				const list1 = [
					{tag: 'input', placeholder: '请输入学生姓名', prop: 'keyword'},
					{tag: 'select', placeholder: '请选择校区', prop: 'school_id', options: [], change: (val, search) => this.onSearchChange(val, search, i)},
					{tag: 'select', placeholder: '请选择年级', prop: 'grade_id', options: [], change: (val, search) => this.onSearchChange(val, search, i)},
					{tag: 'select', placeholder: '请输入行政班', prop: 'class_id', reserveKeyword: true, filterable: true, remote: true, option: [], remoteMethod: (key) => this.getClassList(key, 0, i), change: (val, search) => this.onValueChange(val, search, i)}
				]
				list3[i] = [...list1]
				list4[i] = [...list2]
				if (i === len - 1) {
					list3[i].push({tag: 'select', placeholder: '请选择缴费状态', prop: 'showStatus', options: [{label: '欠缴', value: 1}, {label: '已缴清', value: 2}]})
					list4[i].splice(2, 0, {prop: 'status_text', label: '缴费状态'})
				}
			}
			this.searchConfig = [...list3]
			this.tableConfig = [...list4]
		},
		getSearchConfig() {
			get_school2().then(res => {
				this.searchConfig.forEach(item => {
					item[1].options = res.map(item => ({label: item.school_name, value: item.id}))
				})
			})
			get_grade().then(res => {
				this.searchConfig.forEach(item => {
					item[2].options = res.map(item => ({label: item.grade_name, value: item.id}))
				})
			})
		},
		getClassList(keyword, type, idx) {
			if (!keyword) {
				const item = this.searchConfig[idx][3]
				item.options = []
				this.searchConfig[idx].splice(3, 1, item)
			} else {
				const {school_id, grade_id} = this.$refs.tabsDom.search[this.index]
				const data = {keyword, type}
				if (school_id) {
					data.school_id = school_id
				}
				if (grade_id) {
					data.grade_id = grade_id
				}
				as2_search_class(data).then(res => {
					const item = this.searchConfig[idx][3]
					item.options = res.map(item => ({label: item.class_name, value: item.id}))
					this.searchConfig[idx].splice(3, 1, item)
				})
			}
		},
		onSearchChange(val, search, idx) {
			this.searchConfig[idx][3].options = []
			if (search.class_id) {
				search.class_id = ''
			}
			this.$refs.tabsDom.onChange(search)
		},
		onValueChange(val, search, idx) {
			this.$refs.tabsDom.onChange(search)
		},
		onReset() {
			this.search = {}
			this.showStatus = ''
			this.searchConfig[3].options = []
		}
	},
	data() {
		return {
			index: 0,
			showStatus: '',
			api: '/api2/api/educational-routine/art/pay/get-list',
			list: [
				{label: '欠缴', data: {status: 1}},
				{label: '已缴清', data: {status: 2}},
				{label: '全部', data: {status: 0}}
			],
			searchConfig: [],
			tableConfig: []
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__content {
	overflow: visible !important;
}
</style>
